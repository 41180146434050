import React, { useRef } from 'react'
import loadable from '@loadable/component'
import { Field } from 'formik'

const Button = loadable(() => import('/src/components/Basic/Buttons/Button'))
const CtaContainer = loadable(() =>
    import('/src/components/Basic/CtaContainer')
)
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const LargeCard = loadable(() => import('/src/components/Cards/LargeCard'))
const NumberSlider = loadable(() => import('/src/components/Base/NumberSlider'))

const StepSavings = ({ values }) => {
    const slider = {
        identifier: 'savings',
        max: 30000,
        default: 500,
        input: useRef(null)
    }

    return (
        <LargeCard className={'slick-card'}>
            <FieldSet
                legend={'How much do you have in savings?'}
                groupName={slider.identifier}
            >
                <NumberSlider data={slider} formValues={values} />

                <Field
                    id={'savings'}
                    name={'savings'}
                    value={slider.default}
                    type={'number'}
                    innerRef={slider.input}
                    readOnly
                    className={'slick-card__input'}
                />
            </FieldSet>

            <CtaContainer align={'centered'}>
                <Button type={'submit'} size={'lg'} icon={'chevron'}>
                    Next
                </Button>
            </CtaContainer>
        </LargeCard>
    )
}

export default StepSavings
